import { navigate, Link } from "gatsby"
import { useAdminResetPassword } from "medusa-react"
import qs from "qs"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { decodeToken } from "react-jwt"
import Button from "../components/fundamentals/button"
import MedusaIcon from "../components/fundamentals/icons/medusa-icon"
import SigninInput from "../components/molecules/input-signin"
import SEO from "../components/seo"
import LoginLayout from "../components/templates/login-layout"
import { getErrorMessage } from "../utils/error-messages"
import CheckCircleIcon from "../components/fundamentals/icons/check-circle-icon"
import logoIcon from "../assets/images/logo.png"

type formValues = {
  password: string
  repeat_password: string
  token: string
}

const ResetPasswordPage = ({ location }) => {
  const parsed = qs.parse(location.search.substring(1))
  
  const [passwordMismatch, setPasswordMismatch] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const email = parsed?.email || ""

  const { register, handleSubmit, formState } = useForm<formValues>({
    defaultValues: {
      password: "",
      repeat_password: "",
      token: ""
    },
  })
  const reset = useAdminResetPassword()

  const handleAcceptInvite = (data: formValues) => {
    setPasswordMismatch(false)
    setError(null)

    if (data.password !== data.repeat_password) {
      setPasswordMismatch(true)
      return
    }

    reset.mutate(
      {
        token: data.token as string,
        password: data.password,
        email: email,
      },
      {
        onSuccess: () => {
          navigate("/login")
        },
        onError: (err) => {
          console.log('------err-----', getErrorMessage(err))
          setError(getErrorMessage(err))
        },
      }
    )
  }

  return (
    <LoginLayout>
      <SEO title="Reset Password" />
      <img className="fixed w-[52px] top-[52px] right-[100px]" src={ logoIcon } alt="logo" />
      <div className="flex items-center justify-center w-full h-full">
        <div className="flex min-h-[540px] bg-grey-0 rounded-rounded justify-center">
          <form
            className="flex flex-col py-12 w-full px-[120px] items-center w-[560px]"
            onSubmit={handleSubmit(handleAcceptInvite)}
          >
            <>
              <span className="mt-4 inter-2xlarge-semibold text-grey-90">
                重置密码
              </span>
              <span className="text-center inter-base-regular text-grey-50 mt-xsmall">
                在下面输入您的电子邮件地址，我们将向您发送有关如何重置密码的说明。
              </span>
              <div className="flex text-violet-60 rounded-rounded bg-violet-10 p-base gap-x-small mt-large mb-large">
                <div>
                  <CheckCircleIcon size={20} />
                </div>
                <div className="flex flex-col gap-y-2xsmall">
                  <span className="inter-small-semibold">
                    成功地向您发送了一封电子邮件
                  </span>
                  <span className="inter-small-regular">
                    我们已向您发送了一封电子邮件，您可以使用该电子邮件重置您的密码，如果还未收到，几分钟后再次查看您的邮箱
                  </span>
                </div>
              </div>
              <SigninInput
                placeholder="邮箱"
                name="first_name"
                value={email}
                readOnly
              />
              <SigninInput
                placeholder="Token"
                name="token"
                ref={register({ required: true })}
              />
              <SigninInput
                placeholder="请输入密码"
                type={"password"}
                name="password"
                ref={register({ required: true })}
                autoComplete="new-password"
                maxLength={32}
              />
              <SigninInput
                placeholder="请再次输入密码"
                type={"password"}
                name="repeat_password"
                ref={register({ required: true })}
                autoComplete="new-password"
                className="mb-0"
                maxLength={32}
              />
              {error && (
                <span className="w-full text-rose-50 mt-xsmall inter-small-regular">
                  { error }
                </span>
              )}
              {passwordMismatch && (
                <span className="w-full text-rose-50 mt-xsmall inter-small-regular">
                  两次密码不一致
                </span>
              )}
              <Button
                variant="primary"
                size="large"
                type="submit"
                className="w-full mt-base rounded-rounded"
                loading={formState.isSubmitting}
              >
                重置密码
              </Button>
              <Link
                to="/login"
                className="inter-small-regular text-grey-50 mt-large"
              >
                返回登录
              </Link>
            </>
          </form>
        </div>
      </div>
    </LoginLayout>
  )
}

export default ResetPasswordPage
